import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import VoucherChristmas2019 from 'Components/public/offers/VoucherChristmas2019';

export default class PublicOffer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                offerSlug: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    };

    getOfferComponent = () => {
        const { match } = this.props;

        switch (match.params.offerSlug) {
            case 'voucher-swieta-2019':
                return (<VoucherChristmas2019 {...this.props} />);

            default:
                return null;
        }
    }

    render() {
        return (
            <StyledComponent
                className="component-offer"
                styles={require('./styles')}
            >
                {this.getOfferComponent()}
            </StyledComponent>
        );
    }
}
