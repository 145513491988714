import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Carousel from 'Components/layout/Carousel';
import Button from 'Components/layout/Button';
import Head from 'Components/core/Head';

export default class PublicOffer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    getOffersSection = () => {
        return (
            <section className="section-offers">
                <LayoutContainer>
                    <div className="offers">
                        <div className="offer">
                            <img className="image" src={require('Theme/images/offers/voucher-christmas-2019/voucher-2019-499.png')} />
                            <h3 className="price">600 zł</h3>
                            <div className="features">
                                <p className="feature">Kompleksowy program treningowy</p>
                                <p className="feature">60-minutowy trening diagnostyczny</p>
                                <p className="feature highlight">4 x 60-minutowy trening personalny</p>
                                <p className="feature">Indywidualna konsultacja dietetyczna w gabinecie FitAdept</p>
                                <p className="feature">7-dniowy spersonalizowany plan żywieniowy</p>
                                <p className="feature">Elegancka karta podarunkowa w opakowaniu w formie prezentu bądź voucher online</p>
                            </div>
                            <a
                                href="https://sklep.fitadept.com/koszyk/062c3b27-2e0c-4e42-a057-30e821266a68"
                                target="_blank"
                                rel="noopener noreferrer"
                                id="offer-button"
                                data-offer-name="voucher-499"
                                data-offer-id="062c3b27-2e0c-4e42-a057-30e821266a68"
                            >
                                <Button
                                    icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                                    layout="fullWidth"
                                    size="large"
                                >
                                    Wybieram
                                </Button>
                            </a>
                        </div>
                        <div className="offer">
                            <img className="image" src={require('Theme/images/offers/voucher-christmas-2019/voucher-2019-899.png')} />
                            <h3 className="price">1150 zł</h3>
                            <div className="features">
                                <p className="feature">Kompleksowy program treningowy</p>
                                <p className="feature">60-minutowy trening diagnostyczny</p>
                                <p className="feature highlight">9 x 60-minutowy trening personalny</p>
                                <p className="feature">Indywidualna konsultacja dietetyczna w gabinecie FitAdept</p>
                                <p className="feature">7-dniowy spersonalizowany plan żywieniowy</p>
                                <p className="feature">Elegancka karta podarunkowa w opakowaniu w formie prezentu bądź voucher online</p>
                            </div>
                            <a
                                href="https://sklep.fitadept.com/koszyk/3d2e8538-6817-4468-a9c6-1e1f0d825523"
                                target="_blank"
                                rel="noopener noreferrer"
                                id="offer-button"
                                data-offer-name="voucher-899"
                                data-offer-id="3d2e8538-6817-4468-a9c6-1e1f0d825523"
                            >
                                <Button
                                    icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                                    layout="fullWidth"
                                    size="large"
                                >
                                    Wybieram
                                </Button>
                            </a>
                        </div>
                    </div>
                </LayoutContainer>
            </section>
        );
    }

    render() {
        return (
            <StyledComponent
                className="component-offer-voucher-christmas-2019"
                styles={require('./styles')}
            >
                <Head
                    title="Voucher"
                    description="Dołącz do społeczności FitAdept"
                />
                <section className="section-hero">
                    <LayoutContainer>
                        <div className="logo">
                            <img src={require('Theme/images/logo-dark.svg')} />
                        </div>
                        <h1 className="headline">
                            Karty prezentowe.<br />
                            <span className="small">Podaruj zdrowie i smukłą sylwetkę.</span>
                        </h1>
                    </LayoutContainer>
                </section>
                {this.getOffersSection()}
                <section className="section-why">
                    <LayoutContainer>
                        <h3 className="headline">Dlaczego warto zaufać FitAdept?</h3>
                        <div className="list">
                            <div className="list-element">
                                <img
                                    className="image"
                                    alt="Dlaczego FitAdept #1"
                                    src="https://images.squarespace-cdn.com/content/v1/5c6bc4e792441b2f60f4da89/1557322447104-EBS08NSJ38GGYZ7X7M0Z/ke17ZwdGBToddI8pDm48kAf-OpKpNsh_OjjU8JOdDKBZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwkCFOLgzJj4yIx-vIIEbyWWRd0QUGL6lY_wBICnBy59Ye9GKQq6_hlXZJyaybXpCc/Projekt+bez+tytu%C5%82u+%287%29.png?format=500w"
                                />
                                <p className="title">
                                    Indywidualny plan działania
                                </p>
                                <p className="subtitle">
                                    Dopasowany do wieku, zdrowia, umiejętności i celów trenującego
                                </p>
                            </div>
                            <div className="list-element">
                                <img
                                    className="image"
                                    alt="Dlaczego FitAdept #2"
                                    src="https://images.squarespace-cdn.com/content/v1/5c6bc4e792441b2f60f4da89/1557322823169-MNOB5YTG1PZ8IBJ99CQZ/ke17ZwdGBToddI8pDm48kAf-OpKpNsh_OjjU8JOdDKBZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwkCFOLgzJj4yIx-vIIEbyWWRd0QUGL6lY_wBICnBy59Ye9GKQq6_hlXZJyaybXpCc/Projekt+bez+tytu%C5%82u+%288%29.png?format=500w"
                                />
                                <p className="title">
                                    Autorskie Programy Treningowe
                                </p>
                                <p className="subtitle">
                                    Opracowane w oparciu o ponad 60 tysięcy sesji treningowych!
                                </p>
                            </div>
                            <div className="list-element">
                                <img
                                    className="image"
                                    alt="Dlaczego FitAdept #3"
                                    src="https://images.squarespace-cdn.com/content/v1/5c6bc4e792441b2f60f4da89/1557323010728-SZDVR9DB3AITCYQ8I29X/ke17ZwdGBToddI8pDm48kAf-OpKpNsh_OjjU8JOdDKBZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwkCFOLgzJj4yIx-vIIEbyWWRd0QUGL6lY_wBICnBy59Ye9GKQq6_hlXZJyaybXpCc/Projekt+bez+tytu%C5%82u+%289%29.png?format=500w"
                                />
                                <p className="title">
                                    Najlepsza wykwalifikowana kadra w Polsce
                                </p>
                                <p className="subtitle">
                                    Społeczność certyfikowanych trenerów personalnych, fizjoterapeutów i dietetyków
                                </p>
                            </div>
                        </div>
                    </LayoutContainer>
                </section>
                <section className="section-photo">
                    <img
                        className="image"
                        alt="Dlaczego FitAdept #3"
                        src={require('Theme/images/offers/voucher-christmas-2019/team-bg.jpg')}
                    />
                </section>
                <section className="section-testimonials">
                    <LayoutContainer>
                        <h3 className="headline">Nie wierzysz nam? Zaufaj naszym podopiecznym!</h3>
                        <h4 className="subheadline">
                            Zaufało nam już ponad 1000 osób w tym Michał Milowicz, Lara Gessler, Jakub Rozkosz i Agnieszka Perepeczko. Teraz czas na Ciebie! Zapisz się na trening diagnostyczny i zostań naszym adeptem.
                        </h4>
                        <div className="testimonials">
                            <Carousel>
                                <div className="testimonial">
                                    <p className="quote">
                                        Serdecznie polecam treningi z Fit Adept i trenerem @MichałPalkowski.
                                        Michał to bardzo pozytywny człowiek z profesjonalnym podejściem do treningów.
                                        Dobiera ćwiczenia do celów, które chcemy osiągnąć, koryguje na bieżąco, jeśli coś mi
                                        nie pasuje, urozmaica i pokazuje nowe. Cierpliwie tłumaczy, co i jak należy wykonywać, potrafi
                                        zmotywować i jest wymagający. Treningi z Michałem to mega frajda.
                                    </p>
                                    <p className="author">
                                        Paulina/Facebook
                                    </p>
                                </div>
                                <div className="testimonial">
                                    <p className="quote">
                                        Chodzenie na siłownię zamieniło się wreszcie w treningi. Boli? Musi boleć inaczej trening nieudany.
                                        Dziękuję @Radek Markiewicz za najlepiej poprowadzone treningi ever, doskonały instruktaż i wzór,
                                        czuję postęp i efekty byle dalej byle do przodu, ma boleć!
                                    </p>
                                    <p className="author">
                                        Marcin/Facebook
                                    </p>
                                </div>
                                <div className="testimonial">
                                    <p className="quote">
                                        Ćwiczę z FitAdept prawie rok i naprawdę jestem zadowolona z całego procesu.
                                        Ciągłe bóle pleców odeszły w niepamięć, zaczęłam jeść śniadania (co było nie lada wyczynem)
                                        i mam świadomość możliwości własnego ciała ! Polecam :)
                                    </p>
                                    <p className="author">
                                        Daria/Google
                                    </p>
                                </div>
                                <div className="testimonial">
                                    <p className="quote">
                                        Sprawdzeni i dobrzy trenerzy, motywacja do ćwiczeń, wybór bardzo dużej ilości lokalizacji,
                                        polecam każdemu spróbować :)
                                    </p>
                                    <p className="author">
                                        Małgorzata/Google
                                    </p>
                                </div>
                                <div className="testimonial">
                                    <p className="quote">
                                        Polecam treningi z Arkiem. Zawsze zgodnie z planem, widzę ogromna poprawę formy, a to raptem 3 miesiące.
                                        Sylwetka nabiera kształtu. Ważna jest motywacja! Uważam, ze Arek potrafi tak zmotywować, ze się po prostu chce ćwiczyć!
                                    </p>
                                    <p className="author">
                                        Emilia/Facebook
                                    </p>
                                </div>
                                <div className="testimonial">
                                    <p className="quote">
                                        Od 7 mcy trenuje z Bartoszem - pełen profesjonalizm, skupiony na celu!
                                        Efekty widoczne a aktywność fizyczna stała się dla mnie czymś normalnym
                                        i przyjemnym - za co serdecznie dziękuję Trenerowi.
                                    </p>
                                    <p className="author">
                                        Wioletta/Facebook
                                    </p>
                                </div>
                            </Carousel>
                        </div>
                    </LayoutContainer>
                </section>
                {this.getOffersSection()}
            </StyledComponent>
        );
    }
}