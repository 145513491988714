import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        background: white;

        .section-hero {
            padding: 4em 0 2em 0;

            .layout-container {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;

                .logo {
                    margin-bottom: 2em;

                    img {
                        width: 100%;
                        max-width: 20em;
                    }
                }

                .headline {
                    font-size: 3.8em;
                    color: #333333;
                    text-align: center;
                    font-weight: 300;
                    line-height: 80%;

                    .small {
                        font-size: 55%;
                        font-weight: 600;
                        line-height: 60%;
                    }
                }
            }

            @media (max-width: ${variables.desktopS}) {
                font-size: 90%;
            }

            @media (max-width: ${variables.tabletL}) {
                font-size: 80%;

                .layout-container {
                    .headline {
                        line-height: 100%;

                        .small {
                            margin-top: 2em;
                        }
                    }
                }
            }

            @media (max-width: ${variables.mobileM}) {
                font-size: 70%;
            }
        }
        .section-offers {
            padding-bottom: 5em;

            .offers {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-top: 10em;

                .offer {
                    width: 100%;
                    max-width: 30em;
                    padding: 2em;
                    padding-top: 8em;
                    text-align: center;
                    background: #FFFFFF;
                    border-radius: 1em;
                    margin: 0 1em;
                    position: relative;
                    background: #000000;

                    .image {
                        width: 80%;
                        margin: 0 auto;
                        position: absolute;
                        top: 0;
                        left: 10%;
                        transform: translate(0, -50%);
                        border-radius: 1em;
                    }
                    .price {
                        font-size: 3em;
                        font-weight: 900;
                        color: #c95471;
                        margin-top: .5em;
                    }
                    .features {
                        margin: 2em auto;

                        .feature {
                            text-align: center;
                            margin-bottom: .75em;
                            color: #CCCCCC;

                            &.highlight {
                                font-weight: 600;
                            }
                        }
                    }
                    .component-button {
                        margin-top: 1em;

                        button {
                            font-weight: 600;
                            background-color: #ffec5a;
                            color: #333333;
                        }
                    }

                }
            }

            @media (max-width: ${variables.desktopS}) {
                font-size: 90%;
            }

            @media (max-width: ${variables.tabletL}) {
                font-size: 80%;

                .offers {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .offer {
                        margin: 0;
                        margin-bottom: 12em;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            @media (max-width: ${variables.mobileM}) {
                font-size: 70%;
            }
        }
        .section-why {
            text-align: center;
            padding: 8em 0;
            color: white;

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c95471+0,c91c47+48,c95471+100 */
            background: #c95471; /* Old browsers */
            background: -moz-linear-gradient(-45deg,  #c95471 0%, #c91c47 48%, #c95471 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg,  #c95471 0%,#c91c47 48%,#c95471 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg,  #c95471 0%,#c91c47 48%,#c95471 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c95471', endColorstr='#c95471',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */


            .headline {
                color: white;
                font-size: 3em;
                font-weight: 300;
            }
            .list {
                margin-top: 2em;
                display: flex;
                justify-content: space-around;
                align-items: flex-start;

                .list-element {
                    margin: 0 2em;
                    max-width: 30em;

                    .image {
                        border-radius: 360em;
                        margin-bottom: 1em;
                    }
                    .title {
                        font-size: 1.6em;
                        font-weight: 500;
                        margin-bottom: .5em;
                    }
                    .subtitle {
                        font-size: 1.1em;
                        font-weight: 300;
                    }
                }
            }

            @media (max-width: ${variables.desktopS}) {
                font-size: 90%;
            }

            @media (max-width: ${variables.tabletL}) {
                font-size: 80%;

                .list {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .list-element {
                        margin: 0;
                        margin-bottom: 1em;
                    }
                }
            }

            @media (max-width: ${variables.mobileM}) {
                font-size: 70%;
            }
        }

        .section-photo {

        }

        .section-testimonials {
            text-align: center;
            padding: 6em 0;

            .headline {
                font-size: 3em;
                font-weight: 300;
                color: #333333;
            }
            .subheadline {
                margin-top: .3em;
                font-size: 1.3em;
                line-height: 140%;
                color: #666666;
            }

            .testimonials {
                margin-top: 5em;

                .slick-slider {
                    .slick-arrow {
                        &:before {
                            color: #d43e5a;
                            font-size: 40px;
                        }
                    }
                }

                .testimonial {
                    padding: 0 5em;

                    .quote {
                        font-style: italic;
                        font-size: 1em;
                        color: #666666;
                    }
                    .author {
                        margin-top: .5em;
                        font-size: .9em;
                        color: #777777;
                    }
                }
            }
            .footer {
                margin-top: 6em;

                .disclaimer {
                    margin-top: .5em;
                    font-size: .9em;
                }
            }

            @media (max-width: ${variables.desktopS}) {
                font-size: 90%;
            }

            @media (max-width: ${variables.tabletL}) {
                font-size: 80%;
            }

            @media (max-width: ${variables.mobileM}) {
                font-size: 70%;

                .headline {
                    font-size: 2.5em;
                }
            }
        }

    `;
