import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';

import StyledComponent from 'Components/core/StyledComponent';

export default class Carousel extends Component {
    static propTypes = {
        children: PropTypes.any,
        slickProps: PropTypes.object,
    };
    static defaultProps = {
        slickProps: {
            infinite: true,
            slidesToShow: typeof window !== 'undefined' && window.innerWidth > 1024 ? 2 : 1,
            slidesToScroll: 1,
            arrows: true,
        },
    };

    render() {
        const { children, slickProps } = this.props;

        return (
            <StyledComponent
                className={classnames(
                    'carousel',
                )}
                styles={require('./styles')}
                props={this.props}
                state={this.state}
            >
                <Slider {...slickProps}>
                    {children}
                </Slider>
            </StyledComponent>
        );
    }
}
